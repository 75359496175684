<template>
  <div class="flex-1 d-flex flex-column flex-scroll-height">
    <v-subheader
      class="font-weight-bold pa-0"
      style="font-size: 18px; height: auto"
      >Activities
    </v-subheader>
    <div class="flex-1 flex-scroll-height overflow-y-auto d-flex">
      <v-timeline class="flex-1 pr-4" dense clipped>
        <v-timeline-item
          v-if="task.status === 'open'"
          fill-dot
          class="white--text mb-5"
          color="primary"
          large
        >
          <template #icon>
            <span>{{
              `${authenticatedUser.firstname.charAt(
                0
              )}${authenticatedUser.lastname.charAt(0)}`
            }}</span>
          </template>
          <v-text-field
            v-model="taskMessageInput"
            flat
            label="Leave a comment..."
            solo
            counter="255"
            :readonly="messageLoading"
            @keydown.enter="createTaskMessage"
          >
            <template #append>
              <v-btn
                class="mx-0"
                depressed
                :disabled="!taskMessageInput || taskMessageInput.length > 255"
                small
                :loading="messageLoading"
                color="primary"
                @click="createTaskMessage"
              >
                Post
              </v-btn>
            </template>
          </v-text-field>
        </v-timeline-item>
        <v-timeline-item
          v-for="activity in task.activities"
          :key="activity.id"
          :color="activity.type === 'message' ? 'primary' : 'grey'"
          fill-dot
        >
          <template #icon>
            <span
              v-if="activity.type === 'message'"
              style="font-size: 11px; color: white"
              >{{
                `${activity.user.firstname.charAt(
                  0
                )}${activity.user.lastname.charAt(0)}`
              }}</span
            >
            <v-icon v-else small class="white--text">
              {{ getActivityIcon(activity) }}
            </v-icon>
          </template>
          <v-row
            justify="space-between"
            :style="{
              'background-color':
                activity.type === 'message' ? 'white' : 'transparent',
            }"
          >
            <v-col cols="7" style="font-size: 12px">
              <span
                v-if="activity.type !== 'message'"
                class="font-weight-bold"
                >{{ activity.user.name }}</span
              >
              {{ getActivityMessage(activity) }}
            </v-col>
            <v-col
              class="text-right font-italic"
              cols="5"
              style="font-size: 11px"
            >
              {{ activity.created_at }}
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TaskActivities',
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      taskMessageInput: null,
      messageLoading: false,
    };
  },
  computed: {
    ...mapGetters(['authenticatedUser']),
  },
  methods: {
    async createTaskMessage() {
      this.messageLoading = true;
      await this.$store.dispatch('createTaskMessage', {
        taskId: this.task.id,
        message: this.taskMessageInput,
      });
      this.taskMessageInput = null;
      this.messageLoading = false;
    },
    getActivityMessage(activity) {
      switch (activity.type) {
        case 'task':
          switch (activity.action) {
            case 'create':
              return `created task '${activity.value}'`;
            case 'open':
              return `opened task`;
            case 'close':
              return `closed task`;
            case 'cancel':
              return `canceled task`;
            case 'update':
              if (activity.previous_value) {
                return `changed ${activity.column} from '${activity.previous_value}' to '${activity.value}'`;
              } else {
                return `added ${activity.column} '${activity.value}'`;
              }
          }
          break;
        case 'message':
          return activity.value;
        case 'check':
          switch (activity.action) {
            case 'create':
              return `created check '${activity.value}'`;
            case 'update':
              if (activity.previous_value) {
                // title rename
                return `changed ${activity.column} from '${activity.previous_value}' to '${activity.value}'`;
              } else {
                return `${
                  parseInt(activity.value)
                    ? `marked the checklist item '${activity.column}' as completedChart`
                    : `marked the checklist item '${activity.column}' as incomplete`
                } `;
              }
            case 'delete':
              return `deleted checklist item '${activity.value}'`;
          }
          break;
        case 'appendix':
          switch (activity.action) {
            case 'create':
              return `uploaded appendix '${activity.value}'`;
            case 'delete':
              return `removed appendix '${activity.value}'`;
          }
          break;
        case 'children':
          switch (activity.action) {
            case 'create':
              return `created subtask '${activity.value}'`;
            case 'delete':
              return `removed subtask '${activity.value}'`;
          }
          break;
        case 'label':
          switch (activity.action) {
            case 'create':
              return `added label '${activity.value}'`;
            case 'delete':
              return `removed label '${activity.value}'`;
          }
          break;
      }
    },
    getActivityIcon(activity) {
      switch (activity.type) {
        case 'task':
          return 'mdi-clipboard-edit-outline';
        case 'message':
          return 'mdi-message-reply-text-outline';
        case 'check':
          return 'mdi-format-list-checks';
        case 'appendix':
          return 'mdi-file-outline';
        case 'children':
          return 'mdi-clipboard-multiple-outline';
        case 'label':
          return 'mdi-label-multiple-outline';
      }
    },
  },
};
</script>

<style scoped></style>
